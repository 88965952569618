/* Header */

.nk-header-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 0.25rem 0px;
  transition: all 0.2s linear;
  background-color: #fff;
}
.z-axis{
  z-index: 3;
}
.hover{
  background-color: white;
}
.hover:hover{
  background-color: #f1d9d9;
  transition: 0.2s;
}
.nk-header-main  {
  border-bottom: 1px solid #f4f7fa;
}
.nk-header-main.has-fixed {
  position: fixed;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(7, 19, 33, 0.08);
}

.nk-header-main.will-shrink.has-fixed {
  padding: 0.25rem 0px;
}
.nk-header-wrap {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.templates-container{
  padding: 0 20px 0 20px ;
}
.templates-img{
  transition: 0.5s;
}
.templates-img:hover{
  padding: 0px;
}
.templates-container button{
  border: none;
  background: none;
}
.template-card {
  margin-top: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
}

.template-card::before,
.template-card::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  background-color: rgba(90, 112, 119, 0.415);
  transition: all 0.5s;
}

.template-card::before {
  top: 0;
  right: 0;
  /* border-radius: 0 15px 0 100%; */
}

.template-card::after {
  bottom: 0;
  left: 0;
  /* border-radius: 0 100%  0 15px; */
}

.template-card:hover::before,
.template-card:hover:after {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: all 0.5s;
}

.template-card:hover:after {
  content: "Edit";
}
.ml-2{
  margin-left: 25px;
}
.float-left{
  margin-left: auto;
}
.edu-container{
  padding: 5px;
  margin: 5px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
 
.basicDetailsSubmitBUtton{
	display: flex;
	align-items: center;
}
.flex{
  display: flex;
}
.justify-next{
  justify-content: space-around;
}
.mgl-2{
  margin-left: 10px;
  margin-top: -2px;
}
.mt--5{
  margin-top: 7px;
}
.ml--5px{
  margin-left: -29px;
}
.create-btn-container{
  margin-top: 13px;
}
.submit-btn-bottom {
	margin-bottom: 30px;
}
.submit-btn-up{
	margin-top: 28px;
}
.card{
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}
.flex-navigation{
  display: flex;
}
.flex-navigation em{
  height: fit-content;
  position: relative;
  top: 30%;
  margin-right: 5px;
}
.pagination-select {
  border: none;
  outline: none;
  padding: 0 5px 0 15px;
  margin-right: 10px;
  cursor: pointer;
}
.bg-info-light {
	background-color: rgba(2, 182, 179,0.12) !important;
	color: #1db9aa !important;
}
.bg-primary-light {
	background-color: rgba(17, 148, 247,0.12) !important;
	color: #2196f3 !important;
}
.bg-danger-light {
	background-color: rgba(242, 17, 54,0.12) !important;
	color: #e63c3c !important;
}
.bg-warning-light {
	background-color: rgba(255, 152, 0,0.12) !important;
	color: #f39c12 !important;
}

.nk-editor-body .tox-tinymce {
  border: none;
}
.nk-editor-body .tox:not(.tox-tinymce-inline) .tox-editor-header {
  box-shadow: none;
}
.nk-editor-body .tox .tox-toolbar-overlord > .tox-toolbar__primary {
  border: none;
}

.nk-editor-body .tox .tox-tbtn--bespoke {
  background-color: transparent;
}
.nk-editor-body .tox .tox-tbtn--select {
  margin: 2px 0px 3px 0;
}
.nk-editor-body .tox .tox-toolbar__group {
  padding: 0px 4px 0 4px;
}
.nk-editor-body .tox:not(.tox-tinymce-inline) .tox-editor-header {
  padding: 0;
}
.nk-editor-body .tox .tox-toolbar__group {
  padding-left: 0;
}
.nk-editor-body .tox:not([dir='rtl']) {
  height: 100% !important;
}
.nk-editor-body
  .tox
  .tox-statusbar__text-container.tox-statusbar__text-container--flex-start {
  display: none;
}
.nk-editor-body .tox .tox-statusbar {
  display: none;
}
.nk-editor-body .tox .tox-tbtn {
  transition: all 0.3s ease;
  cursor: pointer;
}
.nk-editor-body .tox .tox-tbtn:hover {
  background: #e5e7eb;
  border: 0;
  box-shadow: none;
  color: #43476b;
}
.tox .tox-collection--list .tox-collection__item--active {
  background-color: #e5e7eb !important;
  color: #43476b;
}
.form-select {
  background-size: 10px 9px;
}
.codeblocksyntaxt {
  margin-top: 25px;
}
.codeblocksyntaxt pre {
  padding: 20px !important;
  margin-bottom: 0;
  border-radius: 7px;
}
.alert-dismissible .btn-close::before {
  content: '\ea06';
  font-family: 'Nioicon' !important;
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  font-size: 14px;
  line-height: 1em;
}
.alert-dismissible .btn-close {
  top: 14px;
  right: 14px;
  padding: 6px;
  border-radius: var(--bs-border-radius-pill) !important;
  background-color: #f2bc16;
  opacity: 1;
  transition: all 0.3s ease;
}
.alert-dismissible .btn-close:hover {
  opacity: 0.85;
}
.dropdown-divider {
  opacity: 1;
  margin: 0.25rem 0;
  margin-left: -4px;
  margin-right: -4px;
}
.fs-15px {
  font-size: 15px;
}
.modal-dialog-scrollable {
  height: 100%;
}
.modal-dialog-scrollable .modal-dialog {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.codeblocksyntaxt span::selection,
.codeblocksyntaxt code::selection {
  background: #e9e9f4;
}
.text-bg-primary .btn-close {
  filter: brightness(0) invert(1);
}
.sidebar-overlay {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}
.is-compact .nk-sidebar-footer {
  display: none;
}
.is-compact:hover .nk-sidebar-footer {
  display: block;
}
.logo-img:not(.logo-icon) {
  height: initial;
  width: initial;
}
.logo-icon {
  height: initial;
}
.btn-custom-icon::after {
  display: none;
  padding: 0;
}
.dropdown-menu-md.dropdown-c-position {
  right: 0px !important;
  left: initial !important;
}
.flex-grow{
  flex-grow: 1;
}
.height-100vh{
  height: 100vh;
}
.h-6{
  height: 20px;
}
.w-6{
  width: 20px;
}
.pt-10px{
padding-top: 10px;
}
.pt-8px{
  padding-top: 8px;
  }
.ml-20px{
  margin-left: 20px;
}
.mr-10px{
  margin-right: 10px;
}
.btn-logout{
  border: none;
  border-top: #43476b !important;
  margin-top: 5px;
  border-radius: 5px;
  width: 100%;
}
.btn-as-a-link{
  border: none;
  background:none;
  width: 100%;
  height: 100%;
  font-size: 1rem;
}
.btn-as-a-link .icon {
  font-size: 1.125rem;
  line-height: 1.725rem;
  display: inline-block;
  width: 2rem;
  display: inline-block;
  text-align: left;
}
.slider {
  background-color: #ffffff2b;
  border-radius: 100px;
  padding: 1px;
  margin: 10px;
  cursor: pointer;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  align-items: center;
  position: relative;
  display: block;
  width: 40px;
  height: 18px;
  box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset, rgba(0, 0, 0, 0.21) 0px 0px 0px 24px inset,
        #22cc3f 0px 0px 0px 0px inset, rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.slider::after {
  content: "";
  display: flex;
  top: 0.5px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #e3e3e3;
  border-radius: 200px;
  position: absolute;
  box-shadow: transparent 0px 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 6px 6px;
  transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  will-change: left, background-color;
}

.switch input[type="checkbox"]:checked + .slider {
  box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset, #22cc3f 0px 0px 0px 2px inset, #22cc3f 0px 0px 0px 24px inset,
        rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.switch input[type="checkbox"]:checked + .slider::after {
  left: 24px;
}

.switch input[type="checkbox"] {
  display: none;
}


@media (max-width: 991px) {
  .nk-header-fixed + .nk-sidebar + .nk-wrap .nk-content {
    margin-top: 3.75rem;
  }
}
@media (max-width: 575px) {
  .nk-block-head-between {
    flex-wrap: wrap;
    gap: 10px;
  }
  .gap-sm-10 {
    gap: 10px 0;
  }
}
.resume-editor-container .has-fixed {
  width: -webkit-fill-available;
  width: -moz-available;
  /* width; */
  /* width:90%; */
  z-index: 4;
  position: fixed;
  top: 12%;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(7, 19, 33, 0.08);

}


@media (min-width: 1200px) {
  .resume-editor-nav{
    width:max-content;
  }
  .resume-editor-container .has-fixed {
     /* width:26%; */
     /* right: 30px; */
    top: 11%;
  }
  
.tab-content{
  margin-top: 20px;
margin-bottom: 80px;
  }
.resume-editor-container{

}

.resume-editor-inner{
  position: fixed;
  overflow-y: auto;
  height: 100vh;
  scrollbar-width: thin;
}
.resume-editor-nav{
  width: fit-content;
}
}

@media (max-width: 500px) {
  .resume-editor-container .has-fixed {
    left: 0;
   top: 8.5%;
   padding: 0px 10px 0 10px ;
 }
 
}

.my-resumes-container{
  padding: 30px;
}
.my-resumes-container button{
  margin-top: 10px;
}
.google-btn{
  background-color: white;
}
.comming-soon{
  text-align: center;
vertical-align: middle;
line-height: 300px;
}