@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900');
.table h4{
    font-family: "Poppins",sans-serif;
    font-weight: 500;
}
.table th{
    font-family: "Poppins",sans-serif;
    font-weight: 600;
}
body{
    font-family: "Poppins",sans-serif;
}
.table-responsive{
	overflow-x: auto; /* Enable horizontal scrolling */
	max-width: 100%;
	min-height: 280px;
  scrollbar-width: thin;
}
table.table td h2.table-avatar {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    white-space: initial;
}
.table .form-control {
	font-size: 14px;
	min-height: 38px;
}

.table {
	color: #272b41;
	max-width: 100%;
	margin-bottom: 0;
	width: 100%;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
	background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
	border-top: 0;
	padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
	white-space: nowrap
	
}
.table.dataTable {
	border-collapse: collapse !important;
}
[data-aos^=fade][data-aos^=fade] {
    opacity: unset ;
    transition-property: opacity,transform;
}
table.table td h2 {
	display: inline-block;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
}
table.table td h2.table-avatar {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
	white-space: nowrap;
}
table.table td h2 a {
	color: #272b41;
}
table.table td h2 a:hover {
	color: #10DEFD;
}
table.table td h2 span {
	color: #888;
	display: block;
	font-size: 12px;
	margin-top: 3px;
}
.table thead {
	border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.table thead tr th {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr:last-child {
	border-color: transparent;
}
.table.table-center td,
.table.table-center th {
	vertical-align: middle;
}
.table-hover tbody tr:hover {
	background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
	color: #474648;
}
.table-striped thead tr {
	border-color: transparent;
}
.table-striped tbody tr {
	border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(235, 235, 235, 0.4);
}
.table-bordered {
	border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
	border-color: rgba(0, 0, 0, 0.05);
}
.card-table .card-body {
    padding: 0;
}
.card-table .card-body .table > thead > tr > th {
    border-top: 0;
}
.card-table .table tr td:first-child,
.card-table .table tr th:first-child {
    padding-left: 1.5rem;
}
.card-table .table tr td:last-child,
.card-table .table tr th:last-child {
    padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
    border-top: 1px solid #e2e5e8;
    padding: 1rem 0.75rem;
    white-space: nowrap;
}
.table>:not(:first-child) {
	border: 0;
}
table.table td,table.table th {
	border-color: #dee2e6;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
}

.contactWrapper{
	display: flex;
	justify-content: space-around;
	width: fit-content;
	margin: auto;
}

/*-----------------
	3. Bootstrap Classes
-----------------------*/

.btn.focus, .btn:focus {
    box-shadow: unset;
}
.approveBtn:hover{
	background-color: #24bf2e4d !important;
}
.reviseBtn:hover{
	background-color: #2424bf4d !important;
}
.paymentBtn:hover{
	transition-duration: 0.25s;
	color: rgb(255, 255, 255);
	background-color: #000270 !important;
}
.paymentBtn:disabled{
	color: rgb(255, 255, 255);
	background-color: #8e8fff97 !important;
}
.paymentBtn{
	color: rgb(254, 254, 254);
	background-color: #0004fb97 !important;
    width: 70px;
}
.removeBtn:hover{
	transition-duration: 0.25s;
	color: rgb(255, 255, 255);
	background-color: #f63131 !important;
}
.removeBtn:disabled{
	color: rgb(255, 255, 255);
	background-color: #ff8e8e97 !important;
}
.removeBtn{
	color: rgb(255, 255, 255);
	background-color: #c60707 !important;
    width: 80px;
}
