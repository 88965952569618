.template2{
  width: 100%;
  font-family:"Poppins",sans-serif;

  /* border: 1px solid #000; */
  /* max-width: 210mm; A4 width */
  aspect-ratio: 1 / 1.414; /* A4 aspect ratio */
  overflow: hidden;
}

.template2-inner{
  height: 100%;
  border: 1px solid #d8d6d4;
  container-type: inline-size;
}
  
 .template2 h1, h2, h3{
    margin: 0;
    color: #000;
  }
  .temp2-title{
    container-type: inline-size;
  }
  .temp2-title h1{
    font-size: 7cqw !important;
  }
  .temp2-title span{
    font-size: 3cqw !important;
  }
  .temp2-header-containers{
    container-type: inline-size;
    margin: 0px !important;
  }
  .temp2-header-container{
    container-type: inline-size;
  }
  .temp2-header-container h2{
    font-size: 3.6cqw !important;
  }
  .temp2-header-containers h3{
    font-size: 3.2cqw !important;
  }
  .temp2-header-containers span{
    font-size: 2.5cqw !important;
  }
  .temp2-date{
    container-type: inline-size;
  }
  

  .temp2-date span{
    font-size: 25cqw !important;
  }

  
  .temp2-sidebar ui{
    container-type: inline-size;
  }
  .temp2-sidebar li{
    font-size: 1.5cqw;
  }
  .temp2-sidebar h1{
    font-size: 2.2cqw !important;
  }

  .tempplate2 li{
    list-style-type: none;
  }

  :root{
    --page-width: 100%;
    --main-width: 70%;
    --sidebar-width: 30%;
    --decorator-horizontal-margin: 0.2in;
  
    --sidebar-horizontal-padding: 0.2in;
  
    /* XXX: using px for very good precision control */
    --decorator-outer-offset-top: 10px;
    --decorator-outer-offset-left: -5.5px;
    --decorator-border-width: 1px;
    --decorator-outer-dim: 9px;
    --decorator-border: 1px solid #ccc;
  
    --row-blocks-padding-top: 5pt;
    --date-block-width: 0.6in;
  
    --main-blocks-title-icon-offset-left: -19pt;
  }
  
  .temp2-main{
    float: left;
    width: var(--main-width);
    padding: 0.25in 0.25in 0 0.25in;
     
      font-size: 1.3cqw;

  }
  
  .temp2-sidebar{
    float: right;
    position: relative; /* for disclaimer */
    width: var(--sidebar-width);
    height: 100%;
    padding: 0.6in var(--sidebar-horizontal-padding);
    background-color: #f2f2f2;
    font-size: 8.5pt;
  }
  
  /* main */
  
  /** big title **/
  .temp2-title, h1, h2{
    text-transform: uppercase;
  }
  
  .temp2-title{
    position: relative;
    left: 0.55in;
    margin-bottom: 0.3in;
    line-height: 1.2;
  }
  
  .temp2-title h1{
    font-weight: 300;
    font-size: 18pt;
    line-height: 1.5;
  }
  
  .temp2-title h1 strong{
    margin: auto 2pt auto 4pt;
    font-weight: 600;
  }
  
  .temp2-subtitle{
    font-size: 8pt;
  }
  
  /*** categorial blocks ***/
  
  .temp2-main-block{
    margin-top: 0.1in;
  }
  
  .temp2-main h2{
    position: relative;
    top: var(--row-blocks-padding-top);
    /* XXX: 0.temp2-5px for aligning fx printing */
    left: calc((var(--date-block-width) + var(--decorator-horizontal-margin)));
    font-weight: 400;
    font-size: 11pt;
    color: #555;
  }
  
  .temp2-main h2 > i{
    /* use absolute position to prevent icon's width from misaligning title */
    /* assigning a fixed width here is no better due to needing to align decorator
       line too */
    position: absolute;
    left: var(--main-blocks-title-icon-offset-left);
    z-index: 1; /* over decorator line */
    color: #444;
  }
  
  
  /**** minor tweaks on the icon fonts ****/
  .temp2-main h2 > .temp2-fa-graduation-cap{
    left: calc(var(--main-blocks-title-icon-offset-left) - 2pt);
    top: 2pt;
  }
  
  .temp2-main h2 > .temp2-fa-suitcase{
    top: 1pt;
  }
  
  .temp2-main h2 > .temp2-fa-folder-open{
    top: 15pt;
  }
  
  /**** individual row blocks (date - decorator - details) ****/
  
  .temp2-blocks{
    display: flex;
    flex-flow: row nowrap;
  }
  
  .temp2-blocks > div{
    padding-top: var(--row-blocks-padding-top);
  }
  
  .temp2-date{
    flex: 0 0 var(--date-block-width);
    padding-top: calc(var(--row-blocks-padding-top) + 2.5pt) !important;
    padding-right: var(--decorator-horizontal-margin);
    font-size: 7pt;
    text-align: right;
    line-height: 1;
  }
  
  .temp2-date span{
    display: block;
  }
  
  .temp2-date span:nth-child(2)::before{
    position: relative;
    top: 1pt;
    right: 5.5pt;
    display: block;
    height: 10pt;
    content: '|';
  }
  
  .temp2-decorator{
    flex: 0 0 0;
    position: relative;
    width: 2pt;
    min-height: 100%;
    border-left: var(--decorator-border);
  }
  
  /*
   * XXX: Use two filled circles to achieve the circle-with-white-border effect.temp2-
   * The normal technique of only using one pseudo element and
   * border: 1px solid white; style makes firefox erroneously either:
   * 1) overflows the grayshade background (if no background-clip is set), or
   * 2) shows decorator line which should've been masked by the white border
   *
   */
  
  /* .temp2-decorator::before{
    position: absolute;
    top: var(--decorator-outer-offset-top);
    left: var(--decorator-outer-offset-left);
    content: ' ';
    display: block;
    width: var(--decorator-outer-dim);
    height: var(--decorator-outer-dim);
    border-radius: calc(var(--decorator-outer-dim) / 2);
    background-color: #fff;
  } */
  
  .temp2-decorator::after{
    position: absolute;
    top: calc(var(--decorator-outer-offset-top) + var(--decorator-border-width));
    left: calc(var(--decorator-outer-offset-left) + var(--decorator-border-width));
    content: ' ';
    display: block;
    width: calc(var(--decorator-outer-dim) - (var(--decorator-border-width) * 2));
    height: calc(var(--decorator-outer-dim) - (var(--decorator-border-width) * 2));
    border-radius: calc((var(--decorator-outer-dim) - (var(--decorator-border-width) * 2)) / 2);
    background-color: #555;
  }
  
  .temp2-blocks:last-child .temp2-decorator{ /* slightly shortens it */
    margin-bottom: 0.25in;
  }
  
  /***** fine-tunes on the details block where the real juice is *****/
  
  .temp2-details{
    flex: 1 0 0;
    padding-left: var(--decorator-horizontal-margin);
    padding-top: calc(var(--row-blocks-padding-top) - 0.5pt) !important; /* not sure why but this is needed for better alignment */
  }
  
  .temp2-details header{
    color: #000;
  }
  
  .temp2-details h3{
    font-size: 9pt;
  }
  
  .temp2-main-block:not(.temp2-concise) .temp2-details div{
    margin: 0.18in 0 0.1in 0; 
  }
  
  .temp2-main-block:not(.temp2-concise) .temp2-blocks:last-child .temp2-details div{
    margin-bottom: 0;
  }
  
  .temp2-main-block.temp2-concise .temp2-details div:not(.temp2-concise){
    /* use padding to work around the fact that margin doesn't affect floated
       neighboring elements */
    padding: 0.05in 0 0.07in 0;
  }
  
  .temp2-details .temp2-place{
    float: left;
    font-size: 7.5pt;
  }
  
  .temp2-details .temp2-location{
    float: right;
  }
  
  .temp2-details div{
    clear: both;
  }
  
  .temp2-details .temp2-location::before{
    display: inline-block;
    position: relative;
    right: 3pt;
    top: 0.25pt;
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    content: "\f041";
  }
  
  /***** fine-tunes on the lists.temp2-.temp2-.temp2- *****/
  
  .temp2-main ul{
    padding-left: 0.07in;
    margin: 0.08in 0;
  }
  
  .temp2-main li{
    margin: 0 0 0.025in 0;
  }
  
  /****** customize list symbol style ******/
  .temp2-main li::before{
    position: relative;
    margin-left: -4.25pt;
    content: '• ';
  }
  .temp2-summary-details{

margin-left: 19px;

  }
  
  .temp2-details .temp2-concise ul{
    margin: 0 !important;
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
  }
  
  .temp2-details .temp2-concise li{
    margin: 0 !important;
  }
  
  .temp2-details .temp2-concise li{
    margin-left: 0 !important;
  }
  
  
  
  /* sidebar */
  
  .temp2-sidebar h1{
    font-weight: 400;
    font-size: 11pt;
  }
  
  .temp2-side-block{
    margin-top: 0.5in; 
  }
  
  .temp2-contact ul{
    margin-top: 0.05in;
    padding-left: 0;
    font-family: "Source Code Pro";
    font-weight: 400;
    line-height: 1.75;
  }
  
  .temp2-contact li > i{
    width: 9pt; /* for text alignment */
    text-align: right;
  }
  
  .temp2-skills{
    line-height: 1.5;
  }
  
  .temp2-skills ul{
    margin: 0.05in 0 0.15in;
    padding: 0;
  }
  .temp2-skills li{
    text-wrap:wrap;
  }
  .temp2-skills li::before{
    position: relative;
    margin-left: -4.25pt;
    content: '• ';
  }
  
.temp2-disclaimer{
    position: absolute;
    bottom: var(--sidebar-horizontal-padding);
    right: var(--sidebar-horizontal-padding);
    font-size: 7.5pt;
    font-style: italic;
    line-height: 1.1;
    text-align: right;
    color: #777;
  }
  

.temp2-disclaimer code{
    color: #666;
    font-family: "Source Code Pro";
    font-weight: 400;
    font-style: normal;
  }










  @media (max-width: 720px) {


    .temp2-main{
      padding: 0.125in 0.125in 0 0.125in;
   
    }
    .temp2-sidebar {
      padding: 0.15in;
    }

    .temp2-main h2 {
      position: relative;
      top: 0px;
      left: 0.4in;
      font-weight: 400;
    
      color: #555;
      height: 8px;
    }
    .temp2-title {
      position: relative;
      left: 0.3in;
      margin-bottom: 0.09in;
      line-height: 1;
    }
    .temp2-main-block {
      margin-top: 0.05in;
    }

    .temp2-blocks:last-child .temp2-decorator {
      margin-bottom: 0in;
    }
    .temp2-date {
      flex: 0 0 0.3in;
      padding-right: 0.1in;
      padding-top: 3.75pt !important;
  text-align: right;
  line-height: 1;
    }
    .temp2-date span:nth-child(2)::before {
      position: relative;
      top: 1pt;
      right: 2.5pt;
      display: block;
      height: 5pt;
      content: '|';
    }   
    /* .temp2-decorator::before {
      position: absolute;
      top:5px;
      left:-2.275px;
      content: ' ';
      width: 4.5px;
      height:4.5px;
      border-radius: 2.5px;

    } */

    .temp2-decorator::after {
      position: absolute;
      top: calc(var(--decorator-outer-offset-top) + var(--decorator-border-width));
      left: -2px;
      content: ' ';
      display: block;
      width:3px;
      height: 3px;
      border-radius: calc((var(--decorator-outer-dim) - (var(--decorator-border-width) * 2)) / 2);
      background-color: #555;
    }
   
    .temp2-blocks > div {
      padding-top: 0.25pt;
    }
  .temp2-summary-details {
    margin-left: 12px;
  }
  .temp2-details {
    flex: 1 0;
    padding-left: 0.1in;
    padding-top: 2.25pt !important;
  }

    .temp2-main-block:not(.temp2-concise) .temp2-details div {
      margin: 0.126in 0 0.05in 0;
    }

    .temp2-main li {
      margin: 0 0 0.0125in 0;
    }

    
  }



















 